<template>
  <main-layout>
    <v-row class="ma-0 grey-2">
      <v-col cols="12" sm="2"></v-col>

      <v-col
          cols="12"
          sm="8"
      >
        <v-sheet
            rounded="lg"
            class="ma-1"
        >
          <!--  -->
        <v-row>
          <v-col cols="12" class="text-center">
            <h2>{{ resource.name }}</h2>
          </v-col>
          <v-col cols="3" class="pa-1 d-flex align-end flex-column">
            <v-img
                :src="resource.frontCover"
                max-width="200"
                contain
            ></v-img>
          </v-col>
          <v-col cols="6" class="pa-1 d-flex align-start flex-column text-left">
            <div id="info" class="">
            <span>
                <span class="pl grey--text text-darken-1 mb-2"> 作者</span>:
                <a class="" href="/search/%E8%AF%BA%E5%9F%83%E5%B0%94%C2%B7%E9%A9%AC%E5%B0%94%E7%A7%91%E5%A7%86">{{author_join_all}}</a>
            </span><br>
              <span class="pl grey--text text-darken-1 mb-2">出版社:</span> {{resource.press===undefined?'无':resource.press.name}}<br>
              <span class="pl grey--text text-darken-1 mb-2">出品方:</span>&nbsp;<a href="https://book.douban.com/series/39059?brand=1">新经典文化</a><br>
              <span class="pl grey--text text-darken-1 mb-2">副标题:</span> 16世纪地中海世界的骑士、海盗、耶稣会士与间谍<br>
              <span class="pl grey--text text-darken-1 mb-2">原作名:</span> Agents of Empire: Knights, Corsairs, Jesuits and Spies in the Sixteenth-Century Mediterranean World<br>
              <span>
               <span class="pl grey--text text-darken-1 mb-2"> 译者</span>:
               <a class="" href="/search/%E4%BD%99%E7%A6%8F%E6%B5%B7">余福海</a>
             </span><br>
              <span class="pl grey--text text-darken-1 mb-2">页数:</span> 584<br>
              <span class="pl grey--text text-darken-1 mb-2">ISBN:</span> {{resource.isrn}}<br>
            </div>
          </v-col>
          <v-col cols="3" class="grey-3">
            评价:评价人数不足
          </v-col>
        </v-row>
        </v-sheet>
        <v-card
            elevation="3"
            outlined
            shaped
            class="ma-1 space-top d-flex align-start flex-column"
        >
        <v-card-title>内容简介  · · · · · ·</v-card-title>
        <v-card-subtitle>《{{resource.name}}》</v-card-subtitle>
        <v-card-text class="text-left">
            {{ resource.description}}
        </v-card-text>
        </v-card>
        <v-card
            elevation="3"
            outlined
            shaped
            class="ma-1 space-top d-flex align-start flex-column"
        >
        <v-card-title>作者简介  · · · · · ·</v-card-title>
        <div class="text-left" v-for="author in resource.authors" v-bind:key="author.id">
          <v-card-subtitle>{{author.name}}</v-card-subtitle>
          <v-card-text class="text-left">
          {{author.description}}
          </v-card-text>
        </div>
        </v-card>
      </v-col>

      <v-col
          cols="12"
          sm="2"
      >
        <v-sheet
            rounded="lg"
            min-height="268"
        >
          <!--  -->
        </v-sheet>
      </v-col>
    </v-row>
  </main-layout>
</template>

<script>
import { find_one } from "@/api/resource";
import MainLayout from '@/layouts/Main'

export default {
  name: "ResourceDetail",
  components:{ MainLayout },
  created: function () {
    this.get_resource()
  },
  data: () => ({
    resource:{},
    author_join: ''
  }),
  computed: {
    author_join_all:function (){
      return this.author_join_fun()
    }
  },
  methods:{
    get_resource: function () {
      console.log('------------------')
      find_one(this.$route.params.id).then(response=>{
        this.resource=response
        this.author_join=this.author_join_fun()
      })
    },
    author_join_fun: function () {
      if(this.resource===undefined||this.resource.authors===undefined||this.resource.authors.length<1)return '--'
      let res =''
      if(this.resource.authors.length<1)return '无'
      this.resource.authors.forEach(function (v){
        res=res+','+v['name']
      })
      return res.substring(1,res.length)
    }
  }
}
</script>

<style scoped>
.space-top{
  margin-top: 25px !important;
  margin-bottom: 0 !important;
}
.text-wrap-auto{
  word-wrap:break-word;
  word-break:break-all;
  overflow: hidden;
}
</style>
